import axios, {AxiosRequestConfig} from "axios";
import {msalInstance} from "./msal-instance";

const graphConfig = {
    graphEndpoint: "https://graph.microsoft.com/v1.0/",
};

const graphLoginRequest = {
    scopes: ["User.Read"],
};

async function getGraphApiConfig() {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error("No user signed in!");
    }

    let response;

    try {
        response = await msalInstance.acquireTokenSilent({
            ...graphLoginRequest,
            account: account,
        });
    } catch {
        response = await msalInstance.acquireTokenPopup({
            ...graphLoginRequest,
            account: account,
        });
    }

    const bearer = `${response.accessToken}`;
    return {
        baseURL: graphConfig.graphEndpoint,
        headers: {
            Authorization: `Bearer ${bearer}`,
        },
    } as AxiosRequestConfig;
}

export async function getUserEmail() {
    const config = await getGraphApiConfig();
    const api = axios.create(config);
    const user = await api.get("me");
    return user.data.mail;
}
