import FolderIcon from "@mui/icons-material/Folder";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useQueryClient } from "@tanstack/react-query";
import { useProjects } from "../Behaviors/projects";
import { Project } from "../api";
import { useStore } from "../State/zustandStore";

export interface props {
  name: string;
  projects: Project[]
}

export interface deleteProps {
  project: Project
}

const Delete = (props: deleteProps) => {
    const projects = useStore().projects;
    const projectId = projects.selectedProjectId;
    const { deleteProject } = useProjects(projectId!);
    return (
        <Tooltip title="Hold shift and click to delete this job and all of its files">
            <DeleteIcon
                className="icon-button-alt"
                onMouseDown={(event) => event.preventDefault()}
                onClick={ async (event) => {
                    if (event.shiftKey && !projects.busy.has(projectId!)){
                        event.stopPropagation(); // Prevent project from being selected
                        await deleteProject(props.project);
                    }}}
            />
        </Tooltip>
    );
};

export const Folder = (props: props) => {
    const { projects } = useStore();
    const queryClient = useQueryClient();
    const projectId = projects.selectedProjectId;

    const truncateString = (str: string, maxLength: number) => {
        if (str.length <= maxLength) {
            return str;
        }
        return `${str.substring(0, maxLength - 3)}...`;
    };

    const renderProjectPopover = (project: any) => {

        let title = project.releaseInfo?.title ?? "";
        let version = project.releaseInfo?.version;

        if (version){
            if (version.length > 14){
                version = truncateString(version, 14);
            }

            version = `(${version})`;
            title = `${truncateString(title, 38 - version.length)} ${version}`;
        }
        else {
            title = truncateString(title, 39);
        }
        return (

            <Popover id="popover-basic">
                {<Popover.Body>
                    <div className="popover-wrapper">
                        <div>{project.releaseInfo?.upc ?? "No UPC Selected"}</div>
                        <div>{project.releaseInfo?.artists ?? " "}</div>
                        <div> {title}</div>
                        <div> {project.releaseInfo?.parentalAdvisory ?? " "}</div>
                        <div> {project.releaseInfo?.configuration ?? " "}</div>
                        <div> {project.releaseInfo?.resolutionType ?? " "}</div>
                    </div>
                </Popover.Body>}
            </Popover>
        );
    };
    return (
        <>
            <tr key={props.name}>
                <td>
                    <FolderIcon />
                </td>
                <td>
                    {`${props.name} (${props.projects.length})`}
                </td>
                <td />
                <td />
            </tr>
            {props.projects.map(project => {
                const isSelected = project.projectId === projectId;
                const hideTrashcan = props.name === "Uploading" || props.name === "In Progress"; /* AT-3670 */
                return (
                    <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="right"
                        overlay={renderProjectPopover(project)}
                        rootClose
                        key={project.projectId!}
                    >
                        <tr key={project.projectId!} className={ `project ${isSelected ? "selected " : ""}`} onClick={async () => {
                            //projects.logProjectEvent(project.projectId!, "Selected project");
                            projects.setSelectedProjectId(project.projectId!);
                            queryClient.invalidateQueries({ queryKey: ["project", project.projectId]});

                            const upcOrIsrc = project?.releaseInfo?.upc; /* AT-3678 TODO ISRC? */
                            const productIdentifier = (upcOrIsrc)
                                ? upcOrIsrc
                                : "No product selected";

                            document.title = `AIT: ${productIdentifier}`;
                        }}>
                            <td style={ {width: "10px"}}> </td>
                            <td className={"project-name"} > {project?.name ?? "New Draft"}  </td>
                            <td className={"shift-hidden"}>{project.projectId}</td>
                            <td style={ { width: "10px" }}>
                                {
                                    hideTrashcan
                                        ? <></>
                                        : <Delete project={project} />
                                }
                            </td>
                        </tr>
                    </OverlayTrigger>
                );
            })
            }
        </>);
};
