import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {MsalProvider} from "@azure/msal-react";
import {QueryCache, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer, toast } from "react-toastify";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {msalInstance} from "./msal-instance";
import "./theme.scss";

const theme = createTheme({
    palette: {
        primary: {
            main: "#035277",
        },
        secondary: {
            main: "#4FC3F7",
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error) => {
            toast.error(`Something when wrong: ${error.message}`);
        },
        // onSettled: (data, error, query) => {
        //     if (query.queryKey[0] === "jobs") {
        //         const jobId = query.queryKey[1];
        //         data.
        //     }
        // },
    }),
});

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
                <ReactQueryDevtools />
            </QueryClientProvider>
        </MsalProvider>
        <ToastContainer
            position="bottom-left"
            autoClose={2000}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnHover={true}
        />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
