import {
    ValidationBusyIcon,
    ValidationErrorIcon,
    ValidationSuccessIcon,
    ValidationWarningIcon,
} from "../StatusIcons/Icons";
import {useValidation} from "../validation/validation";
import {ValidationMessageTypeIcon} from "../StatusIcons/ValidationMessageTypeIcon";
import { useProjects } from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";

export const ProductStatus = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project} = useProjects(projectId);
    const {
        hasProductValidationErrors,
        getProductValidationErrors,
        hasProductValidationRestrictions,
        getProductValidationRestrictions,
        hasProductValidationWarnings,
        getProductValidationWarnings,
        isValidationInProgress,
    } = useValidation();
    const jobId = project?.jobId;

    if (hasProductValidationErrors(jobId)) {
        const errors = getProductValidationErrors(jobId);
        return <div aria-label="Validation Error"><ValidationErrorIcon /> {errors[0].message} (1/{errors.length})</div>;
    } else if (hasProductValidationRestrictions(jobId)) {
        const restrictions = getProductValidationRestrictions(jobId);
        return <>
            <ValidationMessageTypeIcon messageType={restrictions[0].messageType!} /> {restrictions[0].message} (1/{restrictions.length}) </>;
    } else if (hasProductValidationWarnings(jobId)) {
        const warnings = getProductValidationWarnings(jobId);
        return <> <ValidationWarningIcon /> {warnings[0].message} (1/{warnings.length}) </>;
    } else if (isValidationInProgress(jobId)) {
        return <ValidationBusyIcon />;
    } else {
        return <ValidationSuccessIcon />;
    }
};
