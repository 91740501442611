import {IngestionMessage} from "../asset-service-api";
import {useProjects} from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";

export const useDolbyValidators = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project, getMappedResources, getMappedTrack } = useProjects(projectId!);

    const isProductDolby = () => {
        const releaseInfo = project?.releaseInfo;

        switch (releaseInfo?.configuration) {
        case "eAlbum Audio Surround":
        case "eFixedPlaylist Audio Surround":
        case "eSingle Audio/Multi Track Surround":
        case "eSingle Audio/Single Track Surround":
        case "eAlbum Audio (Sollos) Surround":
        case "eSingle Audio/Single Track (Sollos) Surround":
        case "eSingle Audio/Multi Track (Sollos) Surround":
            return releaseInfo.resolutionType === "Dolby Atmos";
        default:
            return false;
        }
    };

    const getDolbyAtmosErrors = (): IngestionMessage[] => {
        if (isProductDolby()) {
            const errorResources = getMappedResources().filter(x => (x.audioInfo?.bit_depth ?? 0) < 24);
            return errorResources.map(x => {
                return {
                    message: `Asset${ x.filename }, assigned to Track ${ getMappedTrack(x)?.number }, is not a Dolby Atmos asset.`,
                    shortMessage: "Tracks on Dolby Atmos products must be Dolby Atmos files.",
                    messageType: "Error",
                    source: {
                        scope: "Track",
                        stage: "local",
                        target: getMappedTrack(x)?.number.toString(),
                        id: "3bfe2ac5-6cd4-4ebd-9977-89d4c65ba28a",
                    },
                } as IngestionMessage;
            });
        } else return [];
    };
    return {isProductDolby, getDolbyAtmosErrors};
};
