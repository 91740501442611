import PublishIcon from "@mui/icons-material/Publish";
import * as React from "react";
import { Button, Tooltip } from "@mui/material";
import { useProjects } from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";
import {useValidation} from "../validation/validation";
import { useAsperaApi } from "../aspera/aspera";
import { UploadStatus } from "../api";
import { CprsPopup } from "../Projects/CprsPopup";

export interface ButtonProps {
    className?: string;
    text: string;
    disabled?: boolean;
}

export const StartButton = (props: ButtonProps) => {
    const projectState = useStore((state) => state.projects);
    const projectId = projectState.selectedProjectId;
    const { project, ingestIfReady, getEffectiveResources } = useProjects(projectId!);
    const jobId = project?.jobId;
    const {hasValidationErrors, hasUnresolvedValidationRestrictions} = useValidation();
    const {uploadResources} = useAsperaApi();
    const [cprsVisible, setCprsVisible] = React.useState(false);

    async function submit(cprsShipInfo?: string[]) {
        if (cprsShipInfo && cprsShipInfo.length > 0) {
            projectState.setSelectedCprsShipments(projectId!, cprsShipInfo);
        }

        try {
            const { started, project: updatedProject } = await ingestIfReady();

            if (!started && updatedProject) {
                const effectiveResources = getEffectiveResources(updatedProject).filter(r => !r.uploadStatus || r.uploadStatus === UploadStatus.Unknown || r.uploadStatus === UploadStatus.Failed);

                await uploadResources(effectiveResources, updatedProject.projectId!);
            }
        } catch (error) {
            console.error(`Error starting ingestion ${error}`);
        } finally {
            projectState.clearProjectMessage(projectId!);
        }
    }

    async function startClick(e: React.MouseEvent<SVGSVGElement> | React.MouseEvent<HTMLButtonElement>) {
        projectState.clearSelectedCprsShipments(projectId!);

        if (project?.cprsInformation?.length! > 0) {
            setCprsVisible(true);
        } else {
            await submit();
        }
    }

    const isDisabled = (props.disabled === true) || hasValidationErrors(jobId) || hasUnresolvedValidationRestrictions(jobId);

    const className = `icon-button icon-button-text ${ isDisabled ? "icon--disabled " : "" }${props.className}`;
    return <>
        <Tooltip title={props.text}>
            <Button color="primary" disableElevation={true} variant="contained" className={className} onClick={(isDisabled ? undefined : startClick)} startIcon={<PublishIcon aria-label={"Start Ingest Button"} />}>
                {props.text}
            </Button>
        </Tooltip>
        <CprsPopup
            visible={cprsVisible}
            onHide={() => setCprsVisible(false)}
            cprsInfo={project?.cprsInformation ?? []}
            onSave={async (cprsShipInfo?: string[]) => submit(cprsShipInfo)}
        />
    </>
    ;
};
