
export const enum ProductTypeEnum {
    Unknown = 0,
    CDAudio, /*DDP*/
    SDAudio,
    HDAudio,
    DolbyAtmos,
    Vinyl,
    Cassette,
    DVD,
    BluRay
};
