/* eslint-disable @typescript-eslint/no-unused-vars */
export default class AudioFileFormat {
    constructor(
        format,
        formatProfile,
        encoding,
        bitsPerSec,
        bitsPerSample,
        channels,
        blockAlign,
        frameRate,
        frameCount,
        fileSize,
        durationMS
    ) {
        this.Format = format;
        this.FormatProfile = formatProfile;
        this.Encoding = encoding;
        this.BitRate = bitsPerSec;
        this.BitDepth = bitsPerSample;
        this.Channels = channels;
        this.FrameSize = blockAlign;
        this.SampleRate = frameRate;
        this.FrameCount = frameCount;
        this.FileSize = fileSize;
        this.DurationMS = durationMS;
    }
}
