import {Table} from "react-bootstrap";
import { Fragment } from "react";
import {DeleteAllButton} from "../../../../Buttons/Buttons";
import {Resource} from "../../../../api";
import {useProjects} from "../../../../Behaviors/projects";
import { ResourceMode, getResourceListMode } from "../../../../Behaviors/projectTools";
import { useStore } from "../../../..//State/zustandStore";
import {FileResource} from "./File";

function getResourceListHeader(mode: ResourceMode) {
    const type = mode ^ ResourceMode.File;
    return `Selected Files (${ResourceMode[type]})`;
}

export const FileList = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project} = useProjects(projectId);
    const mode = getResourceListMode(project?.resources);

    const sortedResources = project?.resources?.sort((a, b) => {
        return (a.relativePath ?? "").localeCompare(b.relativePath ?? "", undefined, {
            numeric: true,
            sensitivity: "base",
        });
    }) ?? [];
    const pathSeparator = sortedResources[0].relativePath?.[0] ?? "\\";
    const firstPath = sortedResources[0].relativePath ?? ""; // Top level folder if it contains files, or first subfolder if not files
    const folder = firstPath.split(pathSeparator)[1];

    let previousPath = `${pathSeparator}${folder}`;
    return (
        <>
            <h3>{getResourceListHeader(mode)}</h3>
            <Table role={"list"}
                aria-labelledby="resources-heading"
                className={"audio-resource-list mb-0 "}
                size="sm">
                <tbody>
                    <tr>
                        <td colSpan={9}>{folder}</td>
                    </tr>
                    {
                sortedResources!.map(item => {
                    let folderName = <></>;

                    if (item.relativePath !== previousPath) {
                        folderName = <tr><td /><td colSpan={8}>{item.relativePath?.split(pathSeparator)[2]}</td></tr>;
                        previousPath = item.relativePath!;
                    }
                    return (
                        <Fragment key={item.id!}>
                            {folderName}
                            <FileResource projectId={projectId!} depth={(item.relativePath ?? "").split(pathSeparator).length - 1} audioResource={item as Resource} />
                        </Fragment>
                    );
                })}
                    <tr className={"filler-row"}>
                        <td colSpan={9} />
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={8} />
                        <td style={{width: 26}}><DeleteAllButton /></td>
                    </tr>
                </tfoot>
            </Table>
        </>
    );
};
