import {Col, Container, Row} from "react-bootstrap";
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useStore } from "../State/zustandStore";
import {StartButton} from "../Buttons/Start";
import {useValidation} from "../validation/validation";
import {useProjects} from "../Behaviors/projects";
import {ValidationMessageTypeIconGroup} from "../StatusIcons/ValidationMessageTypeIcon";
import { ResourceMode } from "../Behaviors/projectTools";
import { CprsRelease } from "../api";
import {PopupNotes} from "./PopupNotes";

interface Props {
    mode: ResourceMode;
}

const cprsInfo = (cprsReleases: CprsRelease[]) => {
    const manufacturers = cprsReleases.reduce((a, c) => {
        const ids = c.manufacturers?.map(m => m.manufacturerId);

        ids?.forEach(i => a.add(i));
        return a;
    }, new Set());

    if (manufacturers.size === 0) {
        return (<></>);
    }
    return (
        <Row>
            <Col xl="6" />
            <Col xl="6" className="cprsMessage">
                {manufacturers.size} manufacturer{manufacturers.size > 1 ? "s" : ""} found in CPRS
            </Col>
        </Row>
    );
};

export const ActionBar = (props: Props) => {
    const [notesEditorVisible, setNotesEditorVisible] = React.useState(false);
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {
        project,
        getSelectedProjectReleaseInfo,
        setOverrideRestrictions,
        isOverrideChecked,
        getEffectiveResources,
    } = useProjects(projectId!);

    const {
        hasOverridableValidationRestrictions,
        isValidationInProgress,
    } = useValidation();

    const jobId = project?.jobId;
    //const inegestionJobState = state.ingestionState.get(projectId!) ?? IngestionJobState.Unknown;

    function getIsStartEnabled() {
        //return inegestionJobState === IngestionJobState.InProgress || inegestionJobState === IngestionJobState.Requested
        //? false
        return !getSelectedProjectReleaseInfo()
            ? false
            : isValidationInProgress(jobId)
                ? false
                : getEffectiveResources().length !== 0;
    }

    async function OverrideChecked(e: React.MouseEvent<HTMLInputElement>) {
        state.setProjectMessage(projectId!, "Setting Override");
        await setOverrideRestrictions(e.currentTarget.checked);
        state.clearProjectMessage(projectId!);
    }
    return (
        <>
            <Container fluid className={" pb-1 pt-1 bg-light"}>
                <>
                    {cprsInfo(project?.cprsInformation ?? [])}
                    <Row>
                        <Col xl="6">
                        Notes: <EditIcon onClick={() => setNotesEditorVisible(true)}
                                className={"icon-button"} /> <br />
                            {/*<textarea className="notes-textarea" readOnly={true} value={getSelectedProject()?.notes??""}> </textarea>*/}
                            <div className="notes-textarea-div">{project?.notes ?? ""}</div>

                            <PopupNotes visible={notesEditorVisible}
                                onHide={() => setNotesEditorVisible(false)} />
                        </Col>
                        <Col xl="6">
                            <ValidationMessageTypeIconGroup />
                            <div className={" float-end  "}>
                                {(hasOverridableValidationRestrictions(jobId)) ? <> <input type={"checkbox"}
                                    defaultChecked={isOverrideChecked()}
                                    onClick={OverrideChecked} /> Override
                                Restrictions </> : null}
                                <StartButton disabled={!getIsStartEnabled()} text={"Submit Job"} />
                            </div>
                        </Col>
                    </Row>
                </>
            </Container>
        </>
    );
};
