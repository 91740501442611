import {useProjects} from "../Behaviors/projects";
import { ProjectState, getProjectState } from "../Behaviors/projectTools";
import { useStore } from "../State/zustandStore";
import { useJob } from "../Behaviors/ingest.api";
import {DraftView} from "./Views/Drafts/DraftView";
import { UploadingView } from "./Views/UploadingView";
import { InProgressView } from "./Views/InProgressView";
import { CompletedView } from "./Views/CompletedView";
import { NeedAttentionView } from "./Views/NeedAttentionView";

export const ProjectView = () => {
    const workspaceState = useStore().projects;
    const {project} = useProjects(workspaceState.selectedProjectId);
    const job = useJob(project?.jobId);

    if (workspaceState.selectedProjectId && !project) {
        return <DraftView />;
    }

    if (project) {
        const state = job ? getProjectState(job!) : ProjectState.Draft;

        switch (state) {
        case ProjectState.Draft:
            return project.isIngestRequestedAfterUpload === true
                ? (<UploadingView />)
                : (<DraftView />);
        case ProjectState.Processing:
            return (<InProgressView />);
        case ProjectState.Completed:
            return (<CompletedView />);
        case ProjectState.NeedsAttention:
            return (<NeedAttentionView />);
        }
        return (<div> Unknown project state: {state}</div>);
    }
    return (<div> No Job Selected</div>);

};
