import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import WarningIcon from "@mui/icons-material/Warning";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CircleIcon from "@mui/icons-material/Circle";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Tooltip} from "@mui/material";
import Explicit from "@mui/icons-material/Explicit";
import {GppGood, GppMaybe, Looks3, Looks4, LooksOne, LooksTwo} from "@mui/icons-material";
import GppBadIcon from "@mui/icons-material/GppBad";

export interface Props {
    size?: "normal" | "large"
    disabled?: boolean
    className?: string
    tooltip?: string
    visible?: string
}

function getClass(props: Props): string {
    const size = (props.size ?? "normal") === "large" ? " icon--large" : "";
    const disabled = (props.disabled ?? false) ? " icon--disabled" : "";
    return `${size + disabled } ${ props.className}`;
}

export const VinylIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${getClass(props)}`} src={"vinyl-icon.svg"} alt={"Asset Ingest Tool Logo"} />
    </Tooltip>;
};

export const CassetteIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${getClass(props)}`} src={"cassette-icon.svg"} alt={"Asset Ingest Tool Logo"} />
    </Tooltip>;
};

export const DvdIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${getClass(props)}`} src={"dvd-icon.svg"} alt={"Asset Ingest Tool Logo"} />
    </Tooltip>;
};

export const BlurayIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${getClass(props)}`} src={"bluray-icon.svg"} alt={"Asset Ingest Tool Logo"} />
    </Tooltip>;
};

export const DolbyIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${ getClass(props)}`} src={"dolby-icon.svg"} alt={"Asset Ingest Tool Logo"} />
    </Tooltip>;
};

export const CdAudioIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${ getClass(props)}`} src={"cd-icon.svg"} alt={"CD Audio Logo"} />
    </Tooltip>;
};

export const HdIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${getClass(props)}`} src={"hd-icon.svg"} alt={"HD Audio Logo"} />
    </Tooltip>;
};

export const SdIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${getClass(props)}`} src={"sd-icon.svg"} alt={"SD Audio Logo"} />
    </Tooltip>;
};

export const DolbyAssetIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${getClass(props)}`} src={"track-asset-dolbyatmos.svg"} alt={"Dolby Asset Logo"} />
    </Tooltip>;
};

export const HdAssetIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${getClass(props)}`} src={"track-asset-icon-hd.svg"} alt={"HD Asset Logo"} />
    </Tooltip>;
};

export const SdAssetIcon = (props: Props) => {
    return <Tooltip title={props.tooltip ?? ""}>
        <img className={`icon ${getClass(props)}`} src={"track-asset-icon-sd.svg"} alt={"SD Asset Logo"} />
    </Tooltip>;
};

export const ValidationErrorIcon = (props: Props) => {
    return <DangerousIcon className={`icon icon--error ${ getClass(props)}`} />;
};

export const ValidationBusyIcon = (props: Props) => {
    return <Tooltip title={"Validating..."}>
        <HourglassBottomIcon className={`icon icon--busy${ getClass(props)}`} />
    </Tooltip>;
};

export const UploadInProgressIcon = (props: Props) => {
    return <Tooltip title={"Waiting for upload to complete..."}>
        <CloudUploadIcon className={`icon icon--busy${ getClass(props)}`} />
    </Tooltip>;
};

export const Step1 = (props: Props) => {
    return <LooksOne className={`icon icon--help${ getClass(props)}`} />;
};

export const Step2 = (props: Props) => {
    return <LooksTwo className={`icon icon--help${ getClass(props)}`} />;
};

export const Step3 = (props: Props) => {
    return <Looks3 className={`icon icon--help${ getClass(props)}`} />;
};

export const Step4 = (props: Props) => {
    return <Looks4 className={`icon icon--help${ getClass(props)}`} />;
};

export const ValidationWarningIcon = (props: Props) => {
    return <WarningIcon aria-label={"Validation Warning"} className={`icon icon--warning ${ getClass(props)}`} />;
};
export const ValidationRestrictionIcon = (props: Props) => {
    return <GppBadIcon aria-label={"Validation Restriction"} className={`icon icon--restriction ${ getClass(props)}`} />;
};

export const ValidationRestrictionResolvableIcon = (props: Props) => {
    return <GppMaybe aria-label={"Validation Restriction"} className={`icon icon--restriction ${ getClass(props)}`} />;
};
export const ValidationRestrictionResolvedIcon = (props: Props) => {
    return <GppGood aria-label={"Validation Restriction"} className={`icon icon--restriction ${ getClass(props)}`} />;
};

export const ValidationInformationIcon = (props: Props) => {
    return <CircleIcon className={`alert-primary${ getClass(props)}`} />;
};

export const ValidationInstructionIcon = (props: Props) => {
    return <ChevronRightIcon className={`icon${ getClass(props)}`} />;
};

export const ValidationPendingUploadIcon = (props: Props) => {
    return <CloudUploadIcon className={`icon icon--busy ${ getClass(props)}`} />;
};

export const ValidationExceptionIcon = (props: Props) => {
    return <ReportIcon className={`icon icon--error${ getClass(props)}`} />;
};

export const ValidationUnknownIcon = (props: Props) => {
    return <ReportIcon className={`icon alert-danger${ getClass(props)}`} />;
};

export const ValidationSuccessIcon = (props: Props) => {
    return <CheckCircleIcon className={`icon icon--success${ getClass(props)}`} />;
};

export const ExplicitIcon = (props: Props) => {
    return <Explicit className={`icon--inverted${ getClass(props)}`} />;
};
