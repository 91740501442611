import {Col, Form, Modal, Row} from "react-bootstrap";
import * as React from "react";
import {useEffect} from "react";
import {useProjects} from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";

export interface Props {
    visible: boolean
    onHide: () => void;
}

export const PopupNotes = (props: Props) => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project, setProjectNotes} = useProjects(projectId!);
    const [currentNotes, setCurrentNotes] = React.useState<string>("");

    const selectedProject = project ?? {projectId: "", notes: ""};
    const {notes} = selectedProject;

    const onHide = React.useCallback(() => {
        props.onHide();
    }, [props]);

    useEffect(() => {
        setCurrentNotes(notes ?? "");
    }, [notes, projectId]);
    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            if (e.code === "Escape") {
                onHide();
            }
        };

        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [onHide, props]);

    async function onSave() {
        onHide();
        await setProjectNotes(currentNotes);
    }
    return (
        <Modal onHide={onHide} show={props.visible}>
            <Modal.Header closeButton>
                <Modal.Title>Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className="mb-3">
                    <Col lg={12}>
                        <Form.Control
                            name="notes" as="textarea"
                            maxLength={1000}
                            defaultValue={notes ?? ""}
                            aria-label={"notes"}
                            onChange={(e) => setCurrentNotes(e.target.value)}
                            placeholder={"max length 1000"}
                            style={{height: "600px", minWidth: "400px"}}
                        />
                        <span className="notes-length-counter">     {currentNotes.length} of 1000 characters</span>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={onSave}>Save</button>
            </Modal.Footer>
        </Modal>
    );
};
