import {Col, Container, Row} from "react-bootstrap";
import { useStore } from "../../../../State/zustandStore";
import {FileList} from "./FileList";
import {DdpTrackList} from "./DdpTrackList";

export const DdpTrackMap = () => {
    const userPreferences = useStore().preferences;
    return (
        <>
            <Container fluid={userPreferences.useFullWidth} className={"wrapper"} id={"mainContain"}>
                <div className={"modal-blocked"}>
                    <Row xl={12} className="mt-3 track-map">
                        <Col lg={6} className="pe-3 table-container--scroll ">
                            <FileList />
                        </Col>

                        <Col lg={6} className="table-container--scroll">
                            <DdpTrackList />
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
