import { useStore } from "../../../State/zustandStore";

export const DraftBusy = () => {
    const store = useStore().projects;
    const projectId = store.selectedProjectId;
    const message = projectId ? store.projectMessages.get(projectId) : undefined;
    return (
        <>
            <div className={message ? "modal-block" : "modal-hidden"}>
                <div className="modal-bod">
                    <div className={"modal-message"} >
                        {message}
                    </div>
                </div>

            </div>
        </>
    );
};
