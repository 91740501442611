import { Col, Container, Row } from "react-bootstrap";
import { ProductSummary } from "../../ProductInfo/ProductSummary";
import { IngestList } from "../IngestList";
import { IngestProgressBar } from "../ProgressBar";
import { Messages } from "../Messages";
import { SendBackButton } from "../../Buttons/Buttons";
import { useStore } from "../../State/zustandStore";

export const NeedAttentionView = () => {
    const userPreferences = useStore().preferences;
    return (
        <Container fluid={userPreferences.useFullWidth} className={"wrapper"} id={"mainContain"}>
            <Row className="mt-3">
                <Col>
                    <h3> Product Info</h3>
                </Col>
            </Row>
            <Row className=" bg-light mt-2 mb-3 pt-1 " style={{ minHeight: "140px" }}>
                <Col>
                    <ProductSummary allowEdit={false} visible={true} />
                </Col>
            </Row>

            <Row className=" bg-light mt-3 mb-3 pt-1 " style={{ minHeight: "60px" }}>
                <Col>
                    <IngestProgressBar />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={"float-end"}>
                        Send back to Drafts <SendBackButton />
                    </div>
                </Col>
            </Row>
            <Row className=" bg-white mt-3 mb-3 pt-1 " style={{ minHeight: "140px" }}>
                <Col>
                    <IngestList />
                </Col>
            </Row>
            <Row className=" bg-white mt-3 mb-3 pt-1 " style={{ minHeight: "140px" }}>
                <Col>
                    <Messages />
                </Col>
            </Row>

        </Container>
    );
};
