import {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {detect} from "detect-browser";
import {useStore} from "./State/zustandStore";
import {BoolSetting} from "./UserPreferences";

export const BrowserSupportModal = () => {
    const userPreferences = useStore().preferences;
    const browser = detect();
    let isSupported = false;

    let name = browser?.name ?? "Unknown Browser";

    if (name === "edge-chromium") {
        if (navigator.userAgent.indexOf("WOW64") === -1) {
            isSupported = true;
        } else {
            isSupported = false;
            name = "Edge (32-bit)";
        }

        isSupported = true;
    }

    if (name === "chrome") {
        if (navigator.userAgent.indexOf("WOW64") === -1) {
            isSupported = true;
        } else {
            isSupported = false;
            name = "Chrome (32-bit)";
        }
    }

    const [settingsVisible, setSettingsVisible] = useState(!userPreferences.hideBrowserWarning && !isSupported);

    const close = (e: KeyboardEvent) => {
        if (e.code === "Escape") {
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);

    function onHide() {
        setSettingsVisible(false);
    }
    return (
        <>
            <Modal onHide={onHide} show={settingsVisible}>
                <Modal.Header closeButton>
                    <Modal.Title><h3>This broswer is not supported.</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        The bowser "{name}" is not fully supported by this application.<br />
                        <br />
                        If you use this browser:
                        <ul>
                            <li> You will not be able to add or upload files from you local machine.</li>
                            <li> You may see unexpected errors.</li>
                        </ul>

                        For the best experience, please use one of the following browsers:
                        <ul>
                            <li>Chrome (64-bit version)</li>
                            <li>Microsoft Edge (64 bit version)</li>
                        </ul>

                        <br />
                        You may need to contact Application Support to have Chrome 64 installed on your computer.
                        <br />

                        <BoolSetting label={"Do not show this warning in the future "} accessor={"hideBrowserWarning"} checked={userPreferences.hideBrowserWarning} />

                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};
