import { Table } from "react-bootstrap";
import { ITrack } from "../../../MapData";
import { FormatDuration, FormatTitle } from "../../../../Formatting";
import { TrackBody } from "../../../TrackBody";
import { TrackStatus } from "../../../../StatusIcons/TrackStatusIcon";
import { useValidation } from "../../../../validation/validation";
import { useProjects } from "../../../../Behaviors/projects";
import { DdpPqDescriptorPacket, ResourceType, Track } from "../../../../api";
import { useStore } from "../../../../State/zustandStore";

// Get the packet with the highest index number for each track
function filterPackets(packets: DdpPqDescriptorPacket[]) {
    if (!packets) {
        return [];
    }

    const maxIndexPackets = packets.reduce((acc, val) => {
        if (
            !acc.find(i => i.trackNumber === val.trackNumber) ||
            acc.find(i => i.trackNumber === val.trackNumber!)?.indexNumber! < val.indexNumber!) {
            acc.push(val);
        }
        return acc;
    }, [] as DdpPqDescriptorPacket[]);
    return maxIndexPackets;
}

interface Column<D> {
    accessor: keyof D,
    Header: string,
    minWidth?: string;
    rowSpan?: number
    colSpan?: number
    class?: string
    Formatter?: Function
}

interface DisplayTrack extends Track {
    ddpTrackDuration: number,
    ddpTrackTitle: string,
}

export const DdpTrackList = () => {
    const {
        hasTrackValidationErrors,
        hasTrackValidationWarnings,
        getTrackValidationErrors,
        getTrackValidationWarnings,
    } = useValidation();
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const { project, getTracks, getSelectedProjectResources } = useProjects(projectId!);
    const jobId = project?.jobId;
    const columns: Column<ITrack>[] = [
        {
            accessor: "number",
            Header: "track",
            class: "iconic",
        },
        {
            accessor: "title",
            Header: "Title",
            class: "word-break full-width",
            colSpan: 4,
            Formatter: FormatTitle,
        },
        {
            accessor: "duration",
            Header: "Duration",
            class: "numeric",
            Formatter: FormatDuration,
        },
        {
            accessor: "isrc",
            Header: "ISRC",
            class: "numeric",
            colSpan: 3,
        },
    ];
    let items: DisplayTrack[] = [];
    const ddpPq = getSelectedProjectResources()?.find(r => r.resourceType === ResourceType.DdpPq);
    const pqDescriptors = ddpPq?.pqDescriptorPackets?.filter(p => p.trackNumber! > 0);
    const pqTracks = filterPackets(pqDescriptors!);

    const trackTitles = getSelectedProjectResources()?.find(r => r.resourceType === ResourceType.DdpText)?.trackTitles;
    const tracks = getTracks();

    if ((!tracks || tracks.length === 0) && pqTracks) { // Don't have product data, but have PQ data
        items = pqTracks?.map(p => {
            const track: DisplayTrack = {
                groupNumber: 0,
                number: p.trackNumber!,
                duration: 0,
                title: "",
                isrc: "",
                versionTitle: "",
                ddpTrackTitle: trackTitles?.find(tt => tt.trackNumber === p.trackNumber)?.title ?? `Track ${p.trackNumber}`,
                ddpTrackDuration: Number(p.durationMs) / 1000,
            };
            return track;
        });
    } else { // Have product data
        items = tracks.map(t => {
            const track: DisplayTrack = {
                groupNumber: 0,
                number: t.number,
                duration: t.duration,
                title: t.title,
                isrc: t.isrc,
                versionTitle: t.versionTitle,
                ddpTrackTitle: trackTitles?.find(tt => tt.trackNumber === t.number)?.title ?? `Track ${t.number}`,
                ddpTrackDuration: Number((pqTracks?.find(p => t.number === p.trackNumber)?.durationMs)) / 1000,
            };

            if (isNaN(track.ddpTrackDuration)) {
                track.ddpTrackDuration = 0;
            }

            if (!pqTracks?.find(p => p.trackNumber === t.number)) {
                track.ddpTrackTitle = "";
            }
            return track;
        });

        const trackNumbers = tracks.map(t => t.number);

        pqDescriptors?.filter(p => !trackNumbers.includes(p.trackNumber!)).forEach(p => {
            const track: DisplayTrack = {
                groupNumber: 0,
                number: p.trackNumber!,
                duration: 0,
                title: "",
                isrc: "",
                versionTitle: "",
                ddpTrackTitle: trackTitles?.find(tt => tt.trackNumber === p.trackNumber)?.title ?? `Track ${p.trackNumber}`,
                ddpTrackDuration: Number(p.durationMs) / 1000,
            };

            items.push(track);
        });
    }
    return (
        <>
            <h3>Product Track List</h3>
            <Table role={"list"}
                aria-labelledby="tracklist-heading"
                className={"product-track-list mb-0  "}
                aria-label="R2 Track List"
                size="sm">
                {
                    items.map(item => {
                        return (
                            <TrackBody key={item.number} track={item}>
                                <tr key={`${item.number}a`}>
                                    <td rowSpan={3} className={"status-iconic"}>
                                        <TrackStatus Track={item} />
                                    </td>
                                    {columns.map(col => {
                                        return (
                                            <td key={col.accessor} rowSpan={col.rowSpan} colSpan={col.colSpan}
                                                className={col.class}
                                                style={{ minWidth: col.minWidth }}>
                                                {col.Formatter ? col.Formatter(item[col.accessor], item.versionTitle) : item[col.accessor]}
                                            </td>
                                        );
                                    },
                                    )}
                                    <td />
                                </tr>
                                <tr key={`${item.number}cv`}>
                                    <td />
                                    <td style={{ textAlign: "left" }} colSpan={4}>
                                        {item.ddpTrackTitle}                                </td>
                                    <td style={{ textAlign: "left" }}> {FormatDuration(item.ddpTrackDuration)}</td>
                                    <td style={{ textAlign: "left" }} />
                                    <td />
                                </tr>
                                {hasTrackValidationWarnings(jobId, item) || hasTrackValidationErrors(jobId, item)
                                    ? <tr key={`${item.number}warn`}>
                                        <td colSpan={9}>
                                            {getTrackValidationErrors(jobId, item).concat(getTrackValidationWarnings(jobId, item))[0].message}
                                        </td>
                                    </tr>
                                    : null
                                }
                            </TrackBody>
                        );
                    })
                }
                <tbody className={"filler-row"}>
                    <tr className={"filler-row"} />
                </tbody>
            </Table>
        </>
    );
};
