import {useEffect, useRef} from "react";
import PublishIcon from "@mui/icons-material/Publish";
import {Col, Form, Modal, Row, FormCheck} from "react-bootstrap";
import { Button } from "@mui/material";
import * as React from "react";
import { CprsManufacturer, CprsRelease } from "../api";

export interface Props {
    visible: boolean;
    onHide: () => void;
    cprsInfo: CprsRelease[];
    onSave: (cprsShipInfo?: string[]) => Promise<void>;
}

export const CprsPopup = (props: Props) => {
    const formRef = useRef<HTMLFormElement>(null);
    const manufacturerInfo = (upc: string, manufacturer: CprsManufacturer) => {
        return (
            <Row key={upc + manufacturer.manufacturerId}>
                <Col lg={1}>
                    <div className="cprs-modal-checkbox">
                        <FormCheck
                            name="cprsRecipient"
                            id={`${upc}|${manufacturer.manufacturerId}`}
                            value={`${upc}|${manufacturer.manufacturerId}`}
                        />
                    </div></Col>
                <Col lg={11}>{manufacturer.name}</Col>
            </Row>
        );
    };
    const releaseInfo = (release: CprsRelease) => {
        const manufacturers = release.manufacturers?.filter(m => m.name).sort((a, b) => a.name!.localeCompare(b.name!));
        const manufacturerRows = manufacturers?.map(m => manufacturerInfo(release.packageUpc!, m));
        return (
            <div key={release.packageUpc}>
                <Row >
                    <Col lg={12}>
                        {release.upc === release.packageUpc ? `UPC: ${release.upc}` : `Package: ${release.packageUpc}`} {release.artist} - {release.title} {release.version ? `(${release.version})` : ""}
                    </Col>
                </Row>
                {manufacturerRows}
            </div>
        );
    };
    const onHide = React.useCallback(() => {
        props.onHide();
    }, [props]);

    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            if (e.code === "Escape") {
                onHide();
            }
        };

        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [onHide, props]);
    async function onSave(event: React.BaseSyntheticEvent) {
        event.preventDefault();

        const formData = new FormData(event.target);
        const selected = formData.getAll("cprsRecipient").map(c => c.toString());

        onHide();
        props.onSave(selected);
    }
    return (
        <Modal onHide={onHide} show={props.visible} style={{ maxWidth: "none !important"}} dialogClassName="cprs-modal" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title><h3>Ship To Manufacturers</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body className="cprs-modal-body">
                <Form id="manufacturers" onSubmit={onSave} ref={formRef}>
                    <Form.Group as={Row} className="mb-3">
                        {props.cprsInfo.filter(r => r.manufacturers?.length! > 0).map(r => releaseInfo(r))}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" disableElevation={true} variant="contained" className="float-end icon-button icon-button-text" type="submit" form="manufacturers" startIcon={<PublishIcon aria-label={"Start Ingest Button"} />}>
                        Submit Job
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
