import { ITrack } from "../Projects/MapData";
import { Track, TrackGroup } from "../api";

export interface ITrackView extends ITrack {
    subTracks: ITrack[] | null;
}

export const transformTrackGroups = (groups: TrackGroup[]): ITrackView[] => {
    return groups.map(g => ({
        number: g.groupNumber,
        duration: g.duration,
        title: "Track Group",
        versionTitle: "",
        isrc: "",
        subTracks: g.tracks,
    }));
};

export const transformTracks = (tracks: Track[]): ITrackView[] => {
    return tracks.map(t => ({
        number: t.number,
        duration: t.duration,
        title: t.title,
        versionTitle: t.versionTitle,
        isrc: t.isrc,
        subTracks: null,
    }));
};
