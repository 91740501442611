import { Tooltip } from "@mui/material";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import {CloudUpload, DeleteSweep} from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNew from "@mui/icons-material/OpenInNew";
import UndoIcon from "@mui/icons-material/Undo";
import ReplyIcon from "@mui/icons-material/Reply";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import CancelIcon from "@mui/icons-material/Cancel";
import {autoMap} from "../Behaviors/automap";
import {useProjects} from "../Behaviors/projects";
import {useAsperaApi} from "../aspera/aspera";
import { useStore } from "../State/zustandStore";
import { useIngestionJobs } from "../Behaviors/ingest.api";

export const AutoMapButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {mapResourcesToTrack, project} = useProjects(projectId!);
    return (
        <Tooltip placement={"right"} title={"Auto Map"}>
            <AutoFixHighIcon className="icon-button icon--large"
                onClick={async () => {
                    try {
                        state.setProjectMessage(projectId!, "Mapping files");

                        const mappedResources = await autoMap(project!);

                        await mapResourcesToTrack(mappedResources);
                    } catch (error) {
                        console.error(`Automap error ${error}`);
                    } finally {
                        state.clearProjectMessage(projectId!);
                    }
                }
                } />
        </Tooltip>);
};
export const ResetMapButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {resetMap} = useProjects(projectId!);
    return (
        <Tooltip placement={"right"} title={"Reset (Unmap all)"}>
            <UndoIcon className="icon-button icon--large"
                onClick={() => resetMap()} />
        </Tooltip>
    );
};

export interface Props {
    disabled?: boolean
}

export const UnMapAllButton = (props: Props) => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {resetMap} = useProjects(projectId!);
    return (
        <Tooltip placement={"right"} title={"Unmap all files"}>
            <ClearAllIcon className={"icon-button "}
                onClick={() => resetMap()} />
        </Tooltip>
    );
};

export const UploadAllButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {project} = useProjects(projectId);
    const {uploadAll} = useAsperaApi();
    return (<Tooltip title={"Upload all files now"}>
        <CloudUpload className="icon-button" onClick={async () => {
            state.setProjectMessage(projectId!, "Preparing Aspera Upload");
            await uploadAll(project!);
            state.clearProjectMessage(projectId!);
        }
        } />
    </Tooltip>
    );
};

export const DeleteAllButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {deleteResources, getUnmappedResources} = useProjects(projectId!);
    const {cancelUpload} = useAsperaApi();
    const deleteAll = async () => {
        const resources = getUnmappedResources();

        try {
            for (const resource of resources) {
                await cancelUpload(resource);
            }
            await deleteResources(resources);
        } catch (error) {
            state.logProjectEvent(projectId!, `Error deleting all resources ${error}}`);
        }
    };
    return (
        <Tooltip title={"Delete all files"}>
            <DeleteSweep className="icon-button" onClick={() => deleteAll()} />
        </Tooltip>);
};

export const RecheckButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {getSelectedProjectResources} = useProjects(projectId!);
    const {refreshResourceUploadStatus} = useAsperaApi();
    return <Tooltip title="Re-check now">
        <RefreshIcon className="icon-button  "
            onClick={async () => {
                const resources = await getSelectedProjectResources();

                if (resources) {
                    await refreshResourceUploadStatus(resources);
                }
            }} />
    </Tooltip>;
};

export const RefreshButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {revalidateProject} = useProjects(projectId!);
    return <Tooltip title="Re-validate now">
        <RefreshIcon className="icon-button  "
            onClick={async () => { await revalidateProject("revalidate button", undefined); }}
        />
    </Tooltip>;
};

export const SendBackButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const { setIsIngestRequestedAfterUpload } = useProjects(projectId!);
    return <Tooltip title="Send this job back to Drafts">
        <ReplyIcon className="icon-button  "
            onClick={async () => { await setIsIngestRequestedAfterUpload(false); }}
        />
    </Tooltip>;
};

export const StopButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {setIsIngestRequestedAfterUpload} = useProjects(projectId!);
    return <Tooltip title="Stop Ingestion">
        <CancelIcon className="icon-button  "
            onClick={async () => {
                await setIsIngestRequestedAfterUpload(false);}
            } />
    </Tooltip>;
};

export const DuplicateButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {duplicateProject} = useProjects(projectId!);
    return <Tooltip title="Create a new draft using these files!">
        <EditIcon className="icon-button  "
            onClick={async () => {
                await duplicateProject(projectId!);
            }
            } />
    </Tooltip>;
};

export const ViewInAstButton = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const { project } = useProjects(projectId!);
    const jobId = project?.jobId;
    const { getJob } = useIngestionJobs();
    const job = getJob(jobId!);

    if ((job.productUrl?.length ?? 0) > 0) {
        return <div className={"float-end"}>
            <span>View ingested assets in AST </span>
            <Tooltip title="View ingested assets in AST">
                <OpenInNew className="icon-button  "
                    onClick={async () => {
                        //alert(job.productUrl);
                        window.open(job.productUrl!, "_blank");
                    }
                    } />
            </Tooltip>
        </div>;
    }
    return <></>;
};
