import { Table } from "react-bootstrap";
import { FormatDuration, FormatTitle } from "../Formatting";
import { TrackStatus } from "../StatusIcons/TrackStatusIcon";
import { ValidationMessageTypeIcon } from "../StatusIcons/ValidationMessageTypeIcon";
import { useValidation } from "../validation/validation";
import { UnMapAllButton } from "../Buttons/Buttons";
import { useProjects, hasMultipleTrackGroups } from "../Behaviors/projects";
import { transformTrackGroups, transformTracks } from "../Behaviors/trackView";
import { Resource } from "../api";
import { useStore } from "../State/zustandStore";
import { TrackBody } from "./TrackBody";
import { TrackGroupBody } from "./TrackGroupBody";
import { DraggableAudioResource } from "./Views/Drafts/UPC_Tracks/DraggableAudioResource";
import { ITrack } from "./MapData";

interface Column<D> {
    accessor: keyof D,
    Header: string,
    minWidth?: string;
    rowSpan?: number
    colSpan?: number
    class?: string
    Formatter?: Function
}

export const TrackList = () => {

    const {
        hasTrackValidationErrors,
        hasTrackValidationWarnings,
        hasTrackValidationRestrictions,
        getTrackValidationErrors,
        getTrackValidationWarnings,
        getTrackValidationInstructions,
        getTrackValidationRestrictions,
    } = useValidation();
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const { project, getMappedResource, getTracks, getTrackGroups } = useProjects(projectId!);
    const jobId = project?.jobId;
    const multipleTrackGroups = hasMultipleTrackGroups(project!);
    const trackViews = multipleTrackGroups // AT-3499
        ? transformTrackGroups(getTrackGroups())
        : transformTracks(getTracks());

    //console.log("TrackGroups", trackGroups.length, "Tracks", tracks.length);

    const columns: Column<ITrack>[] = [
        {
            accessor: "number",
            Header: "Track",
            colSpan: 1,
            class: "center",
        },
        {
            accessor: "title",
            Header: "Title",
            class: "word-break full-width",
            colSpan: 4,
            Formatter: FormatTitle,
        },
        {
            accessor: "duration",
            Header: "Duration",
            class: "numeric",
            Formatter: FormatDuration,
        },
        {
            accessor: "isrc",
            Header: "ISRC",
            class: "numeric",
            colSpan: 3,
        },
    ];
    return (
        <>
            <h3>Product Track List</h3>
            <Table role={"list"}
                aria-labelledby="tracklist-heading"
                className={"product-track-list mb-0  "}
                aria-label="R2 Track List"
                size="sm">
                {
                    trackViews.map(track => {
                        const iAudioResource = getMappedResource(track) as Resource;
                        const hasMappedFile = iAudioResource !== undefined;
                        const hasMessages = hasTrackValidationErrors(jobId, track) || hasTrackValidationRestrictions(jobId, track) || hasTrackValidationWarnings(jobId, track);
                        const topMessage = hasMessages ? getTrackValidationErrors(jobId, track)
                            .concat(getTrackValidationRestrictions(jobId, track))
                            .concat(getTrackValidationWarnings(jobId, track))?.[0]
                            : null;
                        return (
                            <TrackBody key={`${track.number}bdy`} track={track}>
                                <tr key={`${track.number}tbr`}>
                                    {columns.map(col => {
                                        return (
                                            <td key={col.accessor} rowSpan={col.rowSpan} colSpan={col.colSpan}
                                                className={col.class} style={{ minWidth: col.minWidth }}>
                                                {col.Formatter ? col.Formatter(track[col.accessor], track.versionTitle) : track[col.accessor]}
                                            </td>
                                        );
                                    },
                                    )}
                                </tr>
                                { /* AT-3498 added to handle track groups */
                                    track.subTracks ?
                                        <TrackGroupBody key={`${track.number}sub`} subTracks={track.subTracks} />
                                        : null
                                }
                                {iAudioResource ?
                                    <DraggableAudioResource
                                        projectId={projectId!}
                                        isOverlay={false}
                                        isMapped={true}
                                        hideTypeIcon={multipleTrackGroups}
                                        // className={(isDurationEqual(track, iAudioResource)) ? 'alert-success' : 'alert-warning'}
                                        key={iAudioResource.id}
                                        audioResource={iAudioResource} />
                                    : null
                                }
                                {hasMessages
                                    ? <tr key={`${track.number}warn`}>
                                        <td rowSpan={1} className={"status-iconic"}>
                                            <ValidationMessageTypeIcon messageType={topMessage?.messageType!} />
                                        </td>
                                        <td colSpan={9}>
                                            {topMessage?.message}
                                        </td>
                                    </tr>
                                    : null
                                }
                                {
                                    !hasMappedFile &&
                                        !hasTrackValidationWarnings(jobId, track) &&
                                        !hasTrackValidationRestrictions(jobId, track) &&
                                        !hasTrackValidationErrors(jobId, track)
                                        ? <tr key={`${track.number}info`}>
                                            <td rowSpan={1} className={"status-iconic"}>
                                                <TrackStatus Track={track} HasMappedFile={hasMappedFile} />
                                            </td>
                                            <td colSpan={9} className={"track-validation-instruction"}>
                                                {getTrackValidationInstructions(jobId, track, hasMappedFile)[0]?.message ||
                                                    `Drop a file from the left to map it to this track${multipleTrackGroups ? " group" : ""}`}
                                            </td>
                                        </tr>
                                        : null
                                }
                            </TrackBody>
                        );
                    })
                }
                <tbody className={"filler-row"}>
                    <tr className={"filler-row"} />
                </tbody>
                <tfoot>
                    <tr>
                        <td style={{ width: "100%" }} colSpan={9} />
                        <td className={"iconic"}><UnMapAllButton /></td>
                    </tr>
                </tfoot>
            </Table>
        </>
    );
};
