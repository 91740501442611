import { Resource } from "../api";

interface Props {
    audioResource: Resource;
}

export function AudioInfo(prop: Props) {
    return (
        <td key={"audioInfo"} className={"numeric-fixed"} style={{ whiteSpace: "pre-wrap" }}>{prop.audioResource.audioInfo?.sample_rate}  {prop.audioResource.audioInfo?.bit_depth}  {prop.audioResource.audioInfo?.channels}</td>
    );
}
