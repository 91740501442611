import * as React from "react";
import {AsperaStatusIcon, DeleteStopRemoveIconButton} from "../../../Actions/AsperaStatusButtonIcon";
import {Resource} from "../../../../api";

export interface Props {
    projectId: string,
    audioResource: Resource,
    allowEdits?: boolean,
    depth: number,
}

export const FileResource = (props: Props & React.HTMLAttributes<HTMLTableRowElement>) => {
    const formatBytes = (bytes: number | null | undefined) => {
        if (!bytes) return "0 Bytes";

        const k = 1024;
        const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed())} ${sizes[i]}`;
    };
    const indent = (depth: number) => {
        return Array.from(Array(depth)).map((v, i) => {
            return <td key={i} />;
        });
    };
    return (
        <>
            <tr key={props.audioResource.id}
                aria-label={`file${ props.audioResource.filename}`}
                className={props.className}>
                {indent(props.depth)}
                <td colSpan={6 - props.depth} key={"filename"} className={"word-break"}>   {props.audioResource.filename} </td>
                <td className={"numeric"}>{formatBytes(props.audioResource.fileSize)}</td>
                <td className={"iconic"} >
                    <AsperaStatusIcon projectId={props.projectId} resource={props.audioResource} />
                </td>
                <td className={"iconic"} width={10}>
                    <DeleteStopRemoveIconButton resource={props.audioResource} />
                </td>
            </tr>
            <tr className={"shift-hidden"}>
                <td colSpan={6}> localPath: { props.audioResource.localPath} </td>
            </tr>
            <tr className={"shift-hidden"}>
                <td colSpan={6}> uploadStatus: { props.audioResource.uploadStatus} </td>
            </tr>
            <tr className={"shift-hidden"}>
                <td colSpan={6}> resourceId: { props.audioResource.id} </td>
            </tr>
            <tr className={"shift-hidden"}>
                <td colSpan={6}> s3path: {props.audioResource.s3Path} </td>
            </tr>
        </>
    );
};
