import {Modal} from "react-bootstrap";
import * as React from "react";
import {useEffect} from "react";
import {UpcPicker} from "./UpcPicker";

export interface Props {
    visible: boolean
    onHide: () => void;
    onUpcPicked: (upc: string) => void;
    loading: boolean;
    errorMessage: string;
}

export const PopupUpcPicker = (props: Props) => {
    const onHide = React.useCallback(() => {
        if (!props.loading) {
            props.onHide();
        }
    }, [props]);

    useEffect(() => {

        const close = (e: KeyboardEvent) => {
            if (e.code === "Escape") {
                onHide();
            }
        };

        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [onHide, props]);
    return (
        <Modal show={props.visible}>
            <Modal.Header onHide={onHide} closeButton>
                <Modal.Title>Select a new product to ingest.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UpcPicker errorMessage={props.errorMessage} loading={props.loading} onUpcPicked={props.onUpcPicked}
                    visible={true} />
            </Modal.Body>
        </Modal>
    );
};
