import React, {useEffect} from "react";
import "./App.scss";
import {Col, Row} from "react-bootstrap";
import {InteractionType} from "@azure/msal-browser";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useAccount,
    useMsal,
    useMsalAuthentication,
} from "@azure/msal-react";
// needed for drag drop stuff.
import {polyfillDataTransferItem} from "file-system-access";
import {UserPreferencesButton} from "./UserPreferences";
import {BrowserSupportModal} from "./BrowserSupportWarning";
import {WorkspaceView} from "./Workspace/WorkspaceView";
import {ProjectView} from "./Projects/ProjectView";
import { AppVersion } from "./AppVersion";
import { useStore } from "./State/zustandStore";

function getEnv() {
    if (process.env.REACT_APP_ENV === "prod")
    {
        return <></>;
    }
    else
    {
        return <>({process.env.REACT_APP_ENV})</>;
    }
}

function App() {

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "prod" && process.env.REACT_APP_ENV !== "BETA") {
            function shiftPressed(e: KeyboardEvent) {
                if (e.metaKey) {
                    document.body.classList.add("shift-pressed");
                }
            }

            function shiftReleased(e: KeyboardEvent) {
                if (!e.metaKey) {
                    document.body.classList.remove("shift-pressed");
                }
            }

            document.addEventListener("keydown", shiftPressed);

            document.addEventListener("keyup", shiftReleased);
        }
    });

    const preferences = useStore().preferences;
    const {projectLog, selectedProjectId} = useStore().projects;
    const messages = selectedProjectId ? projectLog.get(selectedProjectId) ?? [] : [];

    console.assert(polyfillDataTransferItem);

    useMsalAuthentication(InteractionType.Redirect);

    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0]);

    instance.setActiveAccount(accounts[0]);
    return (
        <>

            <AuthenticatedTemplate>
                <div className={"container-fluid"}>
                    <BrowserSupportModal />
                    <Row lg={12} className="app-header text-nowrap">
                        <Col>
                            <Row
                                className="container-fluid"
                                style={{alignItems: "center"}}
                            >
                                <Col
                                    className="app-header__logo text-nowrap"
                                >
                                    <img
                                        src={"./asset-logo-blue.svg"}
                                        alt={"Asset Ingest Tool Logo"}
                                    />
                                    <div
                                        className=
                                            "app-header__title align-middle"
                                    >
                                                Asset Ingest Tool {getEnv()}
                                    </div>
                                </Col>
                                <Col
                                    className="app-header__user text-nowrap"
                                >
                                    <UserPreferencesButton />
                                    {account?.name ?? "no username"}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row lg={12} className=" pl-8">
                        <Col lg={3}><WorkspaceView /></Col>
                        <Col lg={9} className="center-col">
                            <ProjectView />
                        </Col>
                    </Row>
                </div>

            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {<>
                    <Row lg={12} className="app-header text-nowrap">
                        <Col>
                            <Row className="container-fluid" style={{alignItems: "center"}}>
                                <Col className="app-header__logo text-nowrap ">
                                    <img src={"./asset-logo-blue.svg"} alt={"Asset Ingest Tool Logo"} />
                                    <div className="app-header__title align-middle">Asset Ingest Tool {getEnv()}</div>
                                </Col>
                                <Col className="app-header__user text-nowrap">
                                    Unauthenticated.
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
                }
            </UnauthenticatedTemplate>
            {preferences.enableLog &&
                <div className={"message-box"} style={preferences.logExpanded ? {height: "200px", overflow: "scroll"} : {height: "20px", overflow: "hidden"}}>
                    <div>
                        <span onClick={() => preferences.setPreference("logExpanded", !preferences.logExpanded)}>{preferences.logExpanded ? "⌄" : "⌃"}</span>
                    </div>
                    <div className={"project"}><b>Selected Project: {selectedProjectId}</b></div>
                    <div className={"clipboard"}><button type="button" onClick={() => {navigator.clipboard.writeText(`Selected Project: ${selectedProjectId}\n${messages.join("\n")}`);}}>Copy to clipboard</button></div>
                    <div className={"messages"}>
                        <pre>
                            {messages.map((message, index) => `${message}\n`)}
                        </pre>
                    </div>
                </div>
            }
            <AppVersion />
        </>
    );

}

export default App;
