import {IngestionMessage} from "../asset-service-api";
import {useProjects} from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";

export const useBitDepthValidators = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project, getMappedResources} = useProjects(projectId!);

    const getSampleRateInconsistentErrors = (): IngestionMessage[]=>
    {

        const hasError = new Set(getMappedResources().map(x => x.audioInfo?.sample_rate)).size > 1;

        if (hasError) {
            return [{
                details: "Assigned asset files have inconsistent sample rates.",
                message: "All files must have the same sample rate.",
                messageType: "Error",
                source: {
                    scope: "Product",
                    stage: "local",
                    target: project?.releaseInfo?.upc ?? "unknown",
                    id: "3AAAEF29-80E5-4408-9AE6-4F0ADC21003D",
                },
            } as IngestionMessage];
        } else {
            return [];
        }
    };

    const getBitDepthInconsistentErrors = () : IngestionMessage[] =>
    {
        const hasError = new Set(getMappedResources().map(x => x.audioInfo?.bit_depth)).size > 1;

        if (hasError) {
            return [{
                details: "Assigned asset files have inconsistent bit depths.",
                message: "All files must have the same bit depth.",
                messageType: "Error",
                source: {
                    scope: "Product",
                    stage: "local",
                    target: project?.releaseInfo?.upc ?? "unknown",
                    id: "8372DDD4-FFB3-40C3-A6B8-4CCF7DBC88F3",
                },
            } as IngestionMessage];
        } else {
            return [];
        }
    };
    return {getBitDepthInconsistentErrors, getSampleRateInconsistentErrors};

};
