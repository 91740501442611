import {useQueries, useQuery, useQueryClient} from "@tanstack/react-query";
import {GetProjectsApi} from "../api/apiConfig";
import { Job } from "../asset-service-api";
import { getJobQueryOptions } from "./ingest.api";
import { ProjectState, getProjectState } from "./projectTools";

export const useWorkspace = () => {
    // Get the projects for the user
    const {data: projects = []} = useQuery({
        queryKey: ["projects"],
        queryFn: async () =>
        {
            const api = await GetProjectsApi();
            const response = await api.getProjects();

            if (!response.status) {
                throw new Error("Network response was not ok");
            }
            return response.data;
        },
    });

    // Fetch all of the jobs that appear on projects
    const queryClient = useQueryClient();
    const jobQueries = useQueries({
        queries: projects && Array.isArray(projects)
            ? projects.filter(p => p.jobId).map(p => {
                const existing = queryClient.getQueryData<Job>(["job", p.jobId]);
                return getJobQueryOptions(p.jobId!, existing?.state, existing?.command?.action);
            })
            : [], // if projectIds is undefined, an empty array will be returned
    });

    const jobs = jobQueries.map(j => j.data);

    const jobStates = jobs.map(job => {
        const state = job ? getProjectState(job) : 0 as ProjectState.Unknown;
        return { jobId: job?.id, state};
    });

    const drafts = projects.filter(p => !p.jobId || (jobStates.find(j => j.jobId === p.jobId && j!.state === ProjectState.Draft && p.isIngestRequestedAfterUpload !== true)));
    const pending = projects.filter(p => jobStates.find(j => j.jobId === p.jobId && j.state === ProjectState.Draft && p.isIngestRequestedAfterUpload === true));
    const inProgress = projects.filter(p => jobStates.find(j => j.jobId === p.jobId && j.state === ProjectState.Processing));
    const needsAttention = projects.filter(p => jobStates.find(j => j.jobId === p.jobId && j.state === ProjectState.NeedsAttention));
    const completed = projects.filter(p => jobStates.find(j => j.jobId === p.jobId && j.state === ProjectState.Completed));
    return {
        projects,
        drafts,
        pending,
        inProgress,
        needsAttention,
        completed,
    };
};
