// DragOn manages the relationships between chrome drag dropped files/folder and aspera drag/dropped files and folders.
// We need to be able to get all the files in a a folder or folder tree.
// Problem:
// 1. When a folder is dropped on aspera the "real" path to that folder is received.
// 2. Aspera Provides no way to get files from a folder.
// 3. Chrome can not get contents of a folder from a real path, only a virtual path.
//
// Solution:
// Merge the results of Aspera drop events and React drop events.
// 1. Attach the Aspera drop event Listener and React drop listener to the same object.
// 2. When a file is dropped take the react object and add the aspera real file path from aspera to it.
// 3. When a folder is dropped map the path, and then iterate the contents of directory via / react/chrome.

import {DragDropListener} from "@ibm-aspera/connect-sdk-js/dist/esm/core/types";
import Immutable, {Set} from "immutable";
import {connectClient} from "./aspera";
import {FileSystemFileHandlePath, getAllFiles} from "./dragon.filesystem";

export namespace Dragon {

// clone of Aspera's file object since they don't export it.
    declare type FileObject = {
        lastModifiedDate: string;
        name: string;
        size: number;
        type: string;
    };

    export interface FilesDroppedEvent {
        files: Set<FileSystemFileHandlePath>;
    }

    export interface FilesDroppedListener {
        (result: FilesDroppedEvent): void;
    }

    export interface HandleError {
        (error: Error): void;
    }

    function getSeparator(path: string) {
        if (path.indexOf("/") !== -1) {
            return "/";
        } else {
            return "\\";
        }
    }

    // Data Transfer is the Aspera DataTransfer type
    function zip(asperaFiles: Set<FileObject>, reactFiles: Set<FileSystemFileHandlePath>): Immutable.Set<FileSystemFileHandlePath> {
        let path_RealPath: Immutable.Map<string, string> = Immutable.Map();
        const separator = getSeparator(asperaFiles.first()?.name ?? "");

        for (const asperaFile of asperaFiles) {
            const parts = asperaFile.name.split(separator);

            path_RealPath = path_RealPath.set(parts.slice(-1)[0], parts.slice(0, -1).join(separator));
        }

        for (const reactFile of reactFiles) {
            const realPath = path_RealPath.get(reactFile.entryPath);
            const relativePath = reactFile.relativePath.split("/").join(separator);

            reactFile.systemPath = realPath + relativePath + separator + reactFile.name;
        }
        return reactFiles;
    }

    export const setDropTarget = (cssSelector: string, listener: FilesDroppedListener, onError: HandleError | undefined) => {
        let reactDropEvent: DragEvent | null;
        let asperaDropEvent: DragEvent;

        let reactFiles: Set<FileSystemFileHandlePath>;
        let asperaFiles: Set<FileObject>;
        const callBack = listener;

        function zipper() {
            if ((reactDropEvent && asperaDropEvent && asperaFiles) && reactDropEvent.timeStamp === asperaDropEvent.timeStamp) {
                const files = zip(asperaFiles, reactFiles);

                callBack({files});
            }
        }

        const reactListener = async function reactDropListener(event: any) {
            const dragEvent = event;

            try {
                reactFiles = await getAllFiles(dragEvent);
                reactDropEvent = dragEvent;
                zipper();

            } catch (e) {
                reactDropEvent = null;
                reactFiles = Set();

                if (onError) {
                    if (e as Error)
                        onError(e as Error);
                }
            }
        };

        const asperaListener: DragDropListener = (e) => {
            if (e.files?.dataTransfer?.files) {
                asperaFiles = Set(e.files?.dataTransfer.files);
                asperaDropEvent = e.event;
            }

            zipper();
        };

        const cypressListener = async function cypressListener(event: FilesDroppedEvent) {
            // @ts-ignore
            callBack(event as FilesDroppedEvent);
        };

        function wire() {
            // wire up the aspera listener
            connectClient.setDragDropTargets(cssSelector, {drop: true, allowPropagation: true}, asperaListener);
            document.querySelectorAll(cssSelector)
                .forEach(element => {
                    //wire up the DOM listener
                    element.addEventListener("drop", reactListener);
                    //Wire up the CypressTestListener
                    element.addEventListener("CypressDragonDropEvent", cypressListener);
                },
                );
        }

        function unWire() {
            document.querySelectorAll(cssSelector)
                .forEach(element => {

                    // hack: the dirty dirty way we un-wire aspera events.
                    element = element.cloneNode(true) as Element; // this can't be last, order is important, idk why.
                    element.removeEventListener("drop", reactListener);
                    element.removeEventListener("CypressDragonDropEvent", cypressListener);
                },
                );
        }
        return {wire, unWire};
    };
}
