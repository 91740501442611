export function FormatDuration(seconds: number) {
    if (seconds <= 0) {
        return "";
    }

    var minutes = Math.floor(seconds / 60);
    var secondsM = Math.round(seconds - (minutes * 60));
    return `${(minutes < 10) ? `0${minutes}` : + minutes}:${(secondsM < 10) ? `0${secondsM}` : + secondsM}`;
    // return ( (minutes < 10) ? "0"+minutes : minutes )+ ":"+ (seconds < 10) ?"0"+seconds : seconds
}

export function FormatTitle(title: string, versionTitle?: string | null | undefined) {
    // append versionTitle (if any) to the title
    return versionTitle ? `${title} - ${versionTitle}` : title;
}
