import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {Edit} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import {useProjects} from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";
import {ProductDefinitionIcon} from "./ProductDefinitionIcon";
import { ProductStatus} from "./ProductStatus";

export interface Props {
    allowEdit?: boolean;
    onEditClick?: (ev: React.MouseEvent<any>) => void;
    visible: boolean
}

export const ProductSummary = (props: Props) => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project} = useProjects(projectId);
    const releaseInfo = project?.releaseInfo;

    if (props.visible && releaseInfo != null) {
        return (
            <>
                <Row>
                    <Col lg={6} sm={12} style={{verticalAlign: "center"}}>
                        <dl className="row">
                            <dt aria-label="upc" className="col-md-3 mt-1"> UPC</dt>
                            <dd className="col-sm-9 mt-1">
                                {props.allowEdit ?
                                    <Tooltip title={"Edit UPC"}>
                                        <Edit onClick={props.onEditClick} className={"icon-button me-1"} />
                                    </Tooltip> : null}
                                {releaseInfo.upc}
                            </dd>
                            <dt className="col-md-3">Artist</dt>
                            <dd aria-label="artists" className="col-sm-9 truncate">{releaseInfo.artists}</dd>
                            <dt aria-label="title" className="col-md-3">Title</dt>
                            <dd className="col-sm-9"> {releaseInfo.title}</dd>
                            <dt className="col-md-3 text-truncate">Version</dt>
                            <dd aria-label="version"
                                className="col-sm-9 text-truncate"> {releaseInfo.version === "" ? "[n/a] " : releaseInfo.version}</dd>
                            <dt className="col-md-3 text-truncate">Status</dt>
                            <dd aria-label="status" className="col-sm-9 text-truncate"> {releaseInfo.status}</dd>

                        </dl>

                    </Col>
                    <Col lg={6} sm={12}>
                        <dl className="row">
                            <dt className="col-lg-4 col-md-3 text-truncate">Configuration</dt>
                            <dd aria-label="configuration" className="col-lg-8 col-md-9">{releaseInfo.configuration}
                                <div className={"product-definition float-end"}>
                                    <ProductDefinitionIcon />
                                </div>
                            </dd>
                            <dt className="col-lg-4 col-md-3"> Parental Advisory</dt>
                            <dd aria-label="parental advisory"
                                className="col-lg-8 col-md-9">   {releaseInfo.parentalAdvisory} </dd>

                            <dt className="col-lg-4 col-md-3">Resolution Type</dt>
                            <dd aria-label="resolution type"
                                className="col-lg-8 col-md-9">{releaseInfo.resolutionType === "" ? "[n/a]" : releaseInfo.resolutionType}

                            </dd>
                            <dt className="col-lg-4 col-md-3">Label</dt>
                            <dd aria-label="label" className="col-lg-8 col-md-9">{releaseInfo.labelOwner}</dd>
                            <dt className="col-lg-4 col-md-3">Country</dt>
                            <dd aria-label="country" className="col-lg-8 col-md-9">{releaseInfo.countryOwner}</dd>
                            <dt className="col-lg-4 col-md-3">Embargo Lift Date</dt>
                            <dd aria-label="country" className="col-lg-8 col-md-9">{
                                releaseInfo.embargoed ?
                                    (releaseInfo.embargoLiftDate ?? "Embargoed")
                                    : "[n/a]"
                            }
                            </dd>
                        </dl>
                    </Col>
                </Row>
                <ProductStatus />
            </>
        );
    } else {
        return null;
    }
};
