import { FileSystemFileHandle } from "file-system-access";

export interface DdpmsPacket {
    MapPacketValid: string;
    DataStreamType: string;
    DataStreamPointer: string;
    DataStreamLength: string;
    DataStreamStart: string;
    SubcodeDescriptor: string;
    CdMode: string;
    SourceStorageMode: string;
    SourceMaterialsScrambled: string;
    PreGap1: string;
    PreGap2: string;
    PostGap: string;
    MultipleInputMediaNubmer: string;
    TrackNumber: string;
    IndexNumber: string;
    IsrcCode: string;
    DataStreamIdentifierSize: string;
    DataStreamIdentifier: string;
    PreGap1Next: string;
    PauseAdd: string;
    Offset: string;
    Pad: string;
}

export const DdpmsParse = async (handle: FileSystemFileHandle) => {
    const chunkSize = 128;
    const file = await handle.getFile();
    const reader = new FileReader();
    const packets : DdpmsPacket[] = [];

    const chunkCount = Math.floor(file.size / chunkSize);

    function parsePacket(chunk: Blob) {
        return new Promise<DdpmsPacket>((resolve, reject) => {

            reader.onload = (event: ProgressEvent<FileReader>) => {
                if (event?.target?.error) {
                    reject(event.target.error);
                }

                if (event?.target?.result) {
                    const result = event.target.result.toString();
                    const packet : DdpmsPacket = {
                        MapPacketValid: result.substring(0, 4),
                        DataStreamType: result.substring(4, 6),
                        DataStreamPointer: result.substring(6, 14),
                        DataStreamLength: result.substring(14, 22),
                        DataStreamStart: result.substring(22, 30),
                        SubcodeDescriptor: result.substring(30, 38),
                        CdMode: result.substring(38, 40),
                        SourceStorageMode: result.substring(40, 1),
                        SourceMaterialsScrambled: result.substring(41, 42),
                        PreGap1: result.substring(42, 46),
                        PreGap2: result.substring(46, 50),
                        PostGap: result.substring(50, 54),
                        MultipleInputMediaNubmer: result.substring(54, 55),
                        TrackNumber: result.substring(55, 57),
                        IndexNumber: result.substring(57, 59),
                        IsrcCode: result.substring(59, 71),
                        DataStreamIdentifierSize: result.substring(71, 74),
                        DataStreamIdentifier: result.substring(74, 92),
                        PreGap1Next: result.substring(92, 96),
                        PauseAdd: result.substring(96, 104),
                        Offset: result.substring(104, 113),
                        Pad: result.substring(113, 128),
                    };

                    resolve(packet);
                }
            };
            reader.readAsText(chunk, "ascii");
        });
    }

    for (let i = 0; i < chunkCount; i++) {
        const offset = chunkSize * i;
        const length = Math.min(chunkSize * (i + 1), file.size);
        const chunk = file.slice(offset, length);

        packets.push(await parsePacket(chunk));
    }
    return packets;
};
