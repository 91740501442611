import {useDolbyValidators} from "../validation/doblyAudioValidator";
import { useHdValidator } from "../validation/hd-bitdepth-validator";
import { CdAudioIcon, DolbyIcon, HdIcon, SdIcon, VinylIcon, CassetteIcon, DvdIcon, BlurayIcon } from "../StatusIcons/Icons";
import { ProductTypeEnum } from "../validation/productTypeEnum";
import { getProductType } from "../validation/productTypeValidator";
import { useStore } from "../State/zustandStore";
import { useProjects } from "../Behaviors/projects";

export const ProductDefinitionIcon = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project} = useProjects(projectId);
    const {isProductDolby} = useDolbyValidators();
    const {isProductHd} = useHdValidator();

    if (isProductDolby()) {
        return <DolbyIcon className={"icon icon--xx-large"} tooltip={"Dolby Atmos"} />;
    }

    if (isProductHd()) {
        return <HdIcon className={"icon icon--xx-large"} tooltip={"High Definition Audio"} />;
    }

    const productType = getProductType(project!);

    switch (productType) {
    case ProductTypeEnum.DolbyAtmos:
        return <DolbyIcon className={"icon icon--xx-large"} tooltip={"Dolby Atmos"} />;
    case ProductTypeEnum.HDAudio:
        return <HdIcon className={"icon icon--xx-large"} tooltip={"High Definition Audio"} />;
    case ProductTypeEnum.SDAudio:
        return <SdIcon className={"icon icon--xx-large"} tooltip={"Standard Definition Audio"} />;
    case ProductTypeEnum.CDAudio:
        return <CdAudioIcon className={"icon icon--xx-large"} tooltip={"Compact Disc Audio"} />;
    case ProductTypeEnum.Vinyl:
        return <VinylIcon className={"icon icon--xx-large"} tooltip={"Vinyl"} />;
    case ProductTypeEnum.Cassette:
        return <CassetteIcon className={"icon icon--xx-large"} tooltip={"Cassette"} />;
    case ProductTypeEnum.DVD:
        return <DvdIcon className={"icon icon--xx-large"} tooltip={"DVD"} />;
    case ProductTypeEnum.BluRay:
        return <BlurayIcon className={"icon icon--xx-large"} tooltip={"Blu-ray"} />;
    default: // Unknown or Not supported
        return <></>;
    }
};
