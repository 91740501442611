import { Col, Container, Row } from "react-bootstrap";
import { ProductSummary } from "../../ProductInfo/ProductSummary";
import { IngestList } from "../IngestList";
import { IngestProgressBar } from "../ProgressBar";
import { Messages } from "../Messages";
import { RecheckButton, StopButton } from "../../Buttons/Buttons";
import { useStore } from "../../State/zustandStore";
import { DraftBusy } from "./Drafts/DraftBusy";

export const UploadingView = () => {
    const {preferences} = useStore((state) => state);
    return (
        <Container fluid={preferences.useFullWidth} className={"wrapper"} id={"mainContain"}>
            <DraftBusy />
            <Row className="mt-3">
                <Col>
                    <h3> Product Info</h3>
                </Col>
            </Row>
            <Row className=" bg-light mt-2 mb-3 pt-1 " style={{ minHeight: "140px" }}>
                <Col>
                    <ProductSummary allowEdit={false} visible={true} />
                </Col>
            </Row>

            <Row className=" bg-light mt-3 mb-3 pt-1 " style={{ minHeight: "60px" }}>
                <Col>
                    <IngestProgressBar />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={"float-start"}>
                        <RecheckButton /> Recheck for completed uploads.
                    </div>
                </Col>
                <Col>
                    <div className={"float-end"}>
                        Do not ingest once upload is complete. <StopButton />
                    </div>
                </Col>
            </Row>

            <Row className=" bg-white mt-3 mb-3 pt-1 " style={{ minHeight: "140px" }}>
                <Col>
                    <IngestList />
                </Col>
            </Row>
            <Row className=" bg-white mt-3 mb-3 pt-1 " style={{ minHeight: "140px" }}>
                <Col>
                    <Messages />
                </Col>
            </Row>

        </Container>
    );
};
