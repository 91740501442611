import { useQuery } from "@tanstack/react-query";
import { GetAppApi } from "../api/apiConfig";

export const useApp = () => {
    const { data: app } = useQuery({
        queryKey: ["app"],
        queryFn: async () =>
        {
            const api = await GetAppApi();
            const response = await api.appGet();

            if (!response.status) {
                throw new Error("Network response was not ok");
            }
            return response.data;
        },
        refetchInterval: 1000 * 60 * 5, // Check for updated app settings every 5 minutes
    });

    const features = {
        enableHdForSd: app?.enableHdForSd ?? false,
    };

    const version = app?.version;
    return {
        features,
        version,
    };
};
