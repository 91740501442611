import {Set} from "immutable";
import { FileSystemFileHandle, FileSystemDirectoryHandle, FileSystemHandle } from "file-system-access";
import {trackMapConfig} from "./Projects/Views/Drafts/UPC_Tracks/trackMapConfig";

interface DropPath {
    relativePath: string;
    entryPath: string;
    systemPath: string;
}

export interface FileSystemDirectoryHandlePath extends FileSystemDirectoryHandle, DropPath {
}

export interface FileSystemFileHandlePath extends FileSystemFileHandle, DropPath {
}

async function getRootFiles(
    handles: FileSystemHandle[],
    files: Set<FileSystemFileHandlePath> = Set<FileSystemFileHandlePath>(),
): Promise<Set<FileSystemFileHandlePath>> {
    for await (const handle of handles) {
        if (handle.kind === "file") {
            const fileHandle = handle as FileSystemFileHandlePath;

            fileHandle.relativePath = "";
            fileHandle.entryPath = handle.name;
            files = files.add(handle as FileSystemFileHandlePath);
        }
    }
    return files;
}

async function getRootDirectories(
    handles: FileSystemHandle[],
): Promise<Set<FileSystemDirectoryHandlePath>> {
    let directories = Set<FileSystemDirectoryHandlePath>();

    for (const handle of handles) {
        if (handle?.kind === "directory") {
            const pathHandle = handle as FileSystemDirectoryHandlePath;

            pathHandle.relativePath = "";
            pathHandle.entryPath = handle.name;
            directories = directories.add(pathHandle);
        }
    }
    return directories;
}

async function getSubDirectories(handle: FileSystemDirectoryHandlePath): Promise<Set<FileSystemDirectoryHandlePath>> {
    const entries = handle.entries();
    const directoryHandles: FileSystemDirectoryHandlePath[] = [];

    for await (const entry of entries) {
        if (entry[1]?.kind === "directory") {
            const pathEntry = entry[1] as FileSystemDirectoryHandlePath;

            pathEntry.relativePath = `${handle.relativePath }/${ handle.name}`;
            pathEntry.entryPath = handle.entryPath;
            directoryHandles.push(pathEntry);
        }
    }
    return Set(directoryHandles);
}

async function getSubFiles(handle: FileSystemDirectoryHandlePath): Promise<Set<FileSystemFileHandlePath>> {
    const entries = handle.entries();
    const fileHandles: FileSystemFileHandlePath[] = [];

    for await (const entry of entries) {
        if (entry[1]?.kind === "file") {
            const pathEntry = entry[1] as FileSystemFileHandlePath;

            pathEntry.relativePath = `${handle.relativePath }/${ handle.name}`;
            pathEntry.entryPath = handle.entryPath;
            fileHandles.push(pathEntry);
        }
    }
    return Set(fileHandles);
}

async function getFilesNested(
    handles: FileSystemHandle[],
): Promise<Set<FileSystemFileHandlePath> > {

    let known = await getRootDirectories(handles);
    let visited = Set<FileSystemDirectoryHandlePath>();
    let files = await getRootFiles(handles);
    let unvisited = known;
    let complexity = 0;

    do {
        complexity++;

        const current = unvisited.first();

        if (current) {
            const newDirectories = await getSubDirectories(current);
            const newFiles = await getSubFiles(current);

            files = files.union(newFiles);
            known = known.union(newDirectories);
            complexity += newDirectories.count();
            complexity += newFiles.count();
            visited = visited.add(current);
        }

        unvisited = known.subtract(visited);
    }

    // stop unintended infinite loops and really big directories.
    while (!unvisited.isEmpty() && complexity < trackMapConfig.maxFileDropComplexity);

    if( complexity < trackMapConfig.maxFileDropComplexity)
        return files;
    else
        throw (new Error("Unable to process. Too many items dropped."));

}

async function getHandlesFromDropEvent(event: DragEvent,
): Promise<FileSystemHandle[]> {
    if (event?.dataTransfer?.items) {
        const dataTransferItems = Array.from(event?.dataTransfer?.items);
        const handlePromises = dataTransferItems.map(async x => await x.getAsFileSystemHandle());
        return Promise.all(handlePromises).then(handlesOrNulls => {
            const handles: FileSystemHandle[] = [];

            for (const handle of handlesOrNulls) {
                if (handle) {
                    handles.push(handle);
                }
            }
            return handles;
        });
    } else {
        return [];
    }
}

export async function getAllFiles(event: DragEvent) : Promise<Set<FileSystemFileHandlePath>>{
    const handles = await getHandlesFromDropEvent(event);
    return await getFilesNested(handles);
}
