import {createMD5} from "hash-wasm";
import { FileSystemFileHandle } from "file-system-access";

export const GetMd5Hash = async (handle: FileSystemFileHandle) => {

    const chunkSize = 64 * 1024 * 1024;
    const reader = new FileReader();
    const md5 = await createMD5();
    const file = await handle.getFile();

    md5.init();

    function hashChunk(chunk: Blob) {
        return new Promise<void>((resolve, reject) => {

            reader.onload = (event: ProgressEvent<FileReader>) => {
                if (event?.target?.error) {
                    reject(event.target.error);
                }

                if (event?.target?.result) {
                    const result = event.target.result;
                    const data = new Uint8Array(result as ArrayBuffer);

                    md5.update(data);
                    resolve();
                }
            };
            reader.readAsArrayBuffer(chunk);
        });
    }

    const chunkCount = Math.floor(file.size / chunkSize) + 1;

    for (let i = 0; i < chunkCount; i++) {

        const offset = chunkSize * i;
        const length = Math.min(chunkSize * (i + 1), file.size);
        const chunk = file.slice(offset, length);

        await hashChunk(chunk);
    }
    return md5.digest();
};
