import {useDroppable} from "@dnd-kit/core";
import {ReactNode} from "react";
import {ITrack} from "./MapData";

interface Props{
    track: ITrack
    children?:ReactNode,
}

export function TrackBody(props: Props) {

    const {setNodeRef} = useDroppable({
        id: props.track.number,
    });
    return (
        <tbody aria-label={`Track ${ props.track.number}`} ref={setNodeRef} >
            {props.children}
        </tbody>
    );
}
