import { toast } from "react-toastify";
import { useApp } from "./Behaviors/app";
import { version } from "./autobuild_version";
import "react-toastify/dist/ReactToastify.css";

export function AppVersion() {
    const serverVersion = useApp().version;

    if (serverVersion && serverVersion.trim() !== version) {
        toast.onChange(v => {
            if (v.status === "removed") {
                window.location.reload();
            }
        });
        toast.info("A new version of AIT is available. This view will automatically be refreshed.", { autoClose: false });
    }
    return (
        <div id="info">{version}</div>
    );
}
