// export const msalConfig = {
//     auth: {
//         // Tenant specfic config
//         tenantId:"bbcb6b2f-8c7c-4e24-86e4-6c36fed00b78",
//         authority: "https://login.microsoftonline.com/bbcb6b2f-8c7c-4e24-86e4-6c36fed00b78",
//
//         // Client Specific config. Set in
//         clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
//         redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI as string,
//         postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI as string,
//         navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
//     },
//     cache: {
//         cacheLocation: "sessionStorage", // This configures where your cache will be stored
//         storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
//     }
// };
//
// // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
// export const loginRequest = {
//     // scopes: ["api://nonprod.client.assets.umgapps.com/Application.Access"]//["api://nonprod.client.assets.umgapps.com/User.Read"]
//     scopes: ["api://api.nonprod.assets.umgapps.com/Asset.Get"]//["api://nonprod.client.assets.umgapps.com/User.Read"]
// };
//
//
// //Add the endpoints here for Microsoft Graph API services you'd like to use.
// export const graphConfig = {
//     graphMeEndpoint: "https://graph.microsoft.com"
// };

export const appRoles = {
    Admin: "Admin",
};

export const msalConfig = {
    auth: {
        authority: "https://login.microsoftonline.com/bbcb6b2f-8c7c-4e24-86e4-6c36fed00b78", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI as string,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [process.env.REACT_APP_AIT_API_SCOPE!],
};

export const apiLoginRequest = {
    scopes: [process.env.REACT_APP_INGEST_API_SCOPE!],
};

//Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com",
};
