import {msalInstance} from "../msal-instance";
import {loginRequest} from "../authConfig";
import {AppApi, AsperaUploadApi, ProjectsApi, WorkspaceApi} from "../api";
import {Configuration} from "./configuration";

async function GetAitApiConfig() {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error("No user signed in!");
    }

    let response;

    try {
        response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account,
        });
    } catch {
        response = await msalInstance.acquireTokenPopup({
            ...loginRequest,
            account: account,
        });
    }

    const bearer = `${response.accessToken}`;
    const configuration = new Configuration();

    configuration.basePath = process.env.REACT_APP_AIT_API_BASE_PATH;
    configuration.accessToken = bearer;
    return configuration;
};

export async function GetProjectsApi() {
    const aitApiConfig = await GetAitApiConfig();
    return new ProjectsApi(aitApiConfig);
}

export async function GetWorkspaceApi() {
    const aitApiConfig = await GetAitApiConfig();
    return new WorkspaceApi(aitApiConfig);
}

export async function GetAsperaApi() {
    const aitApiConfig = await GetAitApiConfig();
    return new AsperaUploadApi(aitApiConfig);
}

export async function GetAppApi() {
    const aitApiConfig = await GetAitApiConfig();
    return new AppApi(aitApiConfig);
}
